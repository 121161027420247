import React, { useState } from 'react';
import './searchstyles.css';

const SearchActor = () => {
  // Declare a state variable for the search input
  const [searchQuery, setSearchQuery] = useState('');

  // Handle input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle button click
  const handleSearch = () => {
    alert(`Searching for: ${searchQuery}`);
  };

  return (
    <div style={{overflowX:'scroll', whiteSpace:'nowrap'}} className="tab-buttons">
      <input
        type="text"
        placeholder="Enter Actor Name"
        value={searchQuery}
        onChange={handleInputChange}
      />
      <button
        onClick={handleSearch}
      >
        Search
      </button>
    </div>
  );
};

export default SearchActor;
