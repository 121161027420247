// DecadePagination.js

import React from 'react';
import './DecadePagination.css'; // Import your custom CSS file for styling
//import DecadeImageComponent from './DecadeImageComponent.js';

function DecadePagination({ selectedDecade, decades, onDecadeChange }) {

  if (selectedDecade == null) {
    if (decades != null) {
      if (decades[0] != null) {
        selectedDecade = decades[0];
      }
    }
  }

  return (
    <div className="decade-pagination">
      {decades.map((decade) => (
        <button
          key={decade}
          className={decade === selectedDecade ? 'active' : ''}
          onClick={() => onDecadeChange(decade)}
        >
          {decade}
        </button>
      ))}
    </div>
  );
}

export default DecadePagination;

