// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* YearPagination.css */

.actor-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/ActorPagination.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":["/* YearPagination.css */\n\n.actor-pagination {\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
