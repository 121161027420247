// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */

.footer {
  background-color: black;
  padding: 20px;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-links a {
  color: #fff;
  margin: 0 10px;
  text-decoration: none;
  //padding-left: 50px;
  //padding-right: 150px;
}

/* Media Query for Responsive Footer */
@media (max-width: 600px) {
  .footer-links {
    flex-wrap: wrap;
  }

  .footer-links a {
    margin: 5px;
  }
}

`, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,qBAAqB;EACrB,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA,sCAAsC;AACtC;EACE;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":["/* Footer.css */\n\n.footer {\n  background-color: black;\n  padding: 20px;\n  text-align: center;\n}\n\n.footer-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.footer-links {\n  display: flex;\n  justify-content: center;\n  margin-top: 10px;\n}\n\n.footer-links a {\n  color: #fff;\n  margin: 0 10px;\n  text-decoration: none;\n  //padding-left: 50px;\n  //padding-right: 150px;\n}\n\n/* Media Query for Responsive Footer */\n@media (max-width: 600px) {\n  .footer-links {\n    flex-wrap: wrap;\n  }\n\n  .footer-links a {\n    margin: 5px;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
