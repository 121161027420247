import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function LanguageCard({lang, title, bgcolor}) {
  const url = "/landing?lang=" + lang;
  return (
    <Link style={{padding:30, paddingRight:10}} to={url}>
    <Card style={{ height:300, color: 'white', background:bgcolor, width: '200px', borderRadius:'10%' }}>
      <Card.Title>
      </Card.Title>
      <Card.Body>
        <Card.Text style={{ padding:50}}>
	  {title}
        </Card.Text>
      </Card.Body>
    </Card>
    </Link>
  );
}

export default LanguageCard;
