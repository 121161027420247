// YearPagination.js

import React from 'react';
import './YearPagination.css'; // Import your custom CSS file for styling

function YearPagination({ selectionYear, years, onYearChange }) {
  return (
    <div className="year-pagination">
      {years.map((year) => (
        <button
          key={year}
          className={year === selectionYear ? 'active' : ''}
          onClick={() => onYearChange(year)}
        >
          {year}
        </button>
      ))}
    </div>
  );
}

export default YearPagination;

