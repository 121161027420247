// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* DecadePagination.css */

.decade-pagination {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.decade-pagination button {
  color: white;
  padding: 5px 20px;
  background-color: black;
  cursor: pointer;
}

.decade-pagination button.active {
  color: white;
  background-color: black;
  font-weight: bold;
  border: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/DecadePagination.css"],"names":[],"mappings":"AAAA,yBAAyB;;AAEzB;EACE,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":["/* DecadePagination.css */\n\n.decade-pagination {\n  flex-wrap: wrap;\n  justify-content: center;\n  margin-top: 10px;\n}\n\n.decade-pagination button {\n  color: white;\n  padding: 5px 20px;\n  background-color: black;\n  cursor: pointer;\n}\n\n.decade-pagination button.active {\n  color: white;\n  background-color: black;\n  font-weight: bold;\n  border: 1px solid #ccc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
