// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* tabstyles.css */
.tab-buttons {
  display: flex;
}

.tab-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid black;
  background-color: black;
  width: 200;
  margin: 30px;
  padding: 5px 25px;
  font-family:Copperplate, Papyrus, fantasy;
}

.tab-buttons button.active {
  border: 1px solid white;
  background-color: #black;
}

.tab-content {
  margin-top: 10px;
  padding: 10px;
  //border: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/tabstyles.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,uBAAuB;EACvB,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,yCAAyC;AAC3C;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,wBAAwB;AAC1B","sourcesContent":["/* tabstyles.css */\n.tab-buttons {\n  display: flex;\n}\n\n.tab-buttons button {\n  color: white;\n  cursor: pointer;\n  border: 1px solid black;\n  background-color: black;\n  width: 200;\n  margin: 30px;\n  padding: 5px 25px;\n  font-family:Copperplate, Papyrus, fantasy;\n}\n\n.tab-buttons button.active {\n  border: 1px solid white;\n  background-color: #black;\n}\n\n.tab-content {\n  margin-top: 10px;\n  padding: 10px;\n  //border: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
