// Dropdown.js

import React, { useState } from 'react';

function Dropdown({ options, onSelect }) {
  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    const newValue = event.target.value;
    setSelectedOption(newValue);
    onSelect(newValue);
  };

  return (
    <div>
      <select style={{ backgroundColor: 'transparent' }} value={selectedOption} onChange={handleOptionChange}>
        <option value="">Select an option</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {selectedOption && <p>You selected: {selectedOption}</p>}
    </div>
  );
}

export default Dropdown;

