// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* prev-nextstyles.css */
.prev-next-buttons {
  display: flex;
}

.next-buttons {
  display: flex;
  float: right;
}

.next-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  margin: 30px;
  width: 150px;
  padding: 10px;
  background-color: black;
  font-family:Copperplate, Papyrus, fantasy;
}

.prev-next-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  margin: 30px;
  width: 150px;
  padding: 10px;
  background-color: black;
  font-family:Copperplate, Papyrus, fantasy;
}

.prev-next-buttons button.active {
  border: 1px solid white;
  background-color: #black;
}

.prev-next-buttons button[disabled] {
  color: gray;
  border: 1px solid gray;
  background-color: #gray;
}


.prev-next-actors-buttons button {
  color: white;
  cursor: pointer;
  border: 1px solid white;
  margin: 30px;
  height: 160px;
  padding: 10px;
  background-color: black;
  font-family:Copperplate, Papyrus, fantasy;
}

.prev-next-actors-buttons button.active {
  border: 1px solid white;
  background-color: #black;
}

.prev-next-actors-buttons button[disabled] {
  color: gray;
  border: 1px solid gray;
  background-color: #gray;
}
`, "",{"version":3,"sources":["webpack://./src/prevnextstyles.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,yCAAyC;AAC3C;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,yCAAyC;AAC3C;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,uBAAuB;AACzB;;;AAGA;EACE,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,yCAAyC;AAC3C;;AAEA;EACE,uBAAuB;EACvB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":["/* prev-nextstyles.css */\n.prev-next-buttons {\n  display: flex;\n}\n\n.next-buttons {\n  display: flex;\n  float: right;\n}\n\n.next-buttons button {\n  color: white;\n  cursor: pointer;\n  border: 1px solid white;\n  margin: 30px;\n  width: 150px;\n  padding: 10px;\n  background-color: black;\n  font-family:Copperplate, Papyrus, fantasy;\n}\n\n.prev-next-buttons button {\n  color: white;\n  cursor: pointer;\n  border: 1px solid white;\n  margin: 30px;\n  width: 150px;\n  padding: 10px;\n  background-color: black;\n  font-family:Copperplate, Papyrus, fantasy;\n}\n\n.prev-next-buttons button.active {\n  border: 1px solid white;\n  background-color: #black;\n}\n\n.prev-next-buttons button[disabled] {\n  color: gray;\n  border: 1px solid gray;\n  background-color: #gray;\n}\n\n\n.prev-next-actors-buttons button {\n  color: white;\n  cursor: pointer;\n  border: 1px solid white;\n  margin: 30px;\n  height: 160px;\n  padding: 10px;\n  background-color: black;\n  font-family:Copperplate, Papyrus, fantasy;\n}\n\n.prev-next-actors-buttons button.active {\n  border: 1px solid white;\n  background-color: #black;\n}\n\n.prev-next-actors-buttons button[disabled] {\n  color: gray;\n  border: 1px solid gray;\n  background-color: #gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
