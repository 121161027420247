import React from 'react';

const Music = (props) => {
  return (
    <div>
      <h1>Hello, {props.name}!</h1>
    </div>
  );
}

export default Music;

