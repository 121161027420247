// ComingSoon.js

import React from 'react';
//import './ComingSoon.css'; // Import your custom CSS file for styling

function ComingSoon(props) {
  return (
      <center>
      <h1>
<br/>
<br/>
<br/>
         {props.lang} | Coming soon...
<br/>
      </h1>
      <p>
<br/>
<br/>
<br/>
          Very soon we will be launching more content on this website. So, please stay tuned with us for any updates.
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
      </p>
      </center>
  );
}

export default ComingSoon;

