// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* YearPagination.css */

.year-pagination {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.year-pagination button {
  color:white;
  padding: 5px 20px;
  background-color: black;
  cursor: pointer;
}

.year-pagination button.active {
  background-color: black;
  font-weight: bold;
  border: 1px solid #ccc;
}

`, "",{"version":3,"sources":["webpack://./src/YearPagination.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;EACE,eAAe;EACf,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,uBAAuB;EACvB,iBAAiB;EACjB,sBAAsB;AACxB","sourcesContent":["/* YearPagination.css */\n\n.year-pagination {\n  flex-wrap: wrap;\n  justify-content: center;\n  margin-top: 10px;\n}\n\n.year-pagination button {\n  color:white;\n  padding: 5px 20px;\n  background-color: black;\n  cursor: pointer;\n}\n\n.year-pagination button.active {\n  background-color: black;\n  font-weight: bold;\n  border: 1px solid #ccc;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
