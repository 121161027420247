import React, { useEffect, useState } from 'react';
import VideoPlayer from './VideoPlayer';
import { useLocation, Link } from 'react-router-dom'; // useLocation goes along with useNavigate defined in another component
import config from './config';
import { useMediaQuery } from 'react-responsive';
import { Image } from 'react-native';

const MovieDetails = (props) => {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  console.log("Inside MovieDetails");
  console.log("Inside MovieDetails isMobile :" + isMobile);

  const location = useLocation();
  const state = location.state || {};
  const movieId = state.movieId;

  console.log("movie Idlocalhost :" + movieId);
  const url = `${config.apiUrl}/movies/id/` + movieId;

  const [movieDetails, setMovieDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMovieDetails(url).then((result) => {
      setMovieDetails(result);
    });
  }, [url]);

  const fetchMovieDetails = async (url) => {
 
    console.log('Inside fetchData for url :' + url);

    let jsonData = [];

    try {
      const response = await fetch(url);
      jsonData = await response.json();

      return jsonData;
   
    } catch (error) {
      jsonData = [];
      console.error('Error fetching data:', error);
    } finally{
        setLoading(false);
    }
  };


  return (
    <div>
    {isMobile && movieDetails ? (<div style={{display:'block', textAlign:'left'}}>
	<div>

	   <div>
	   { 
             (movieDetails != null && movieDetails.movie.movieTrailerCode != null) && (<VideoPlayer title="Movie Trailer" videoId={movieDetails.movie.movieTrailerCode} isMobile={isMobile}/>)
	   }
	   </div>

	   <div>
	   { 
             (movieDetails != null && movieDetails.movie.movieCode != null) && (<VideoPlayer title="Movie Video" videoId={movieDetails.movie.movieCode} isMobile={isMobile}/>)
	   }
	   </div>

           <br/>
           <br/>
           <br/>


	<div style={{paddingLeft:40}}>
	  <h3> Movie Details </h3>

          <Image
            source={movieDetails.movie.moviePoster}
            alt={movieDetails.movie.movieName}
            style={styles.defaultImage}
          />

	  <p> Movie : {movieDetails.movie.movieName} </p>
	  <p> Movie Release Date : {movieDetails.movie.movieReleaseDate} </p>
	  <p> Language : {movieDetails.movie.movieLanguage} </p>

          <p> Cast : </p>
          {movieDetails.actors.map((item) => (
            <li key={item.actorId} style={{fontSize:'12px'}}>
              <Link style={{textDecoration:'none', color:'white'}} to={item.actorUrl}>{item.actorName}</Link>
            </li>
          ))}

          <p> Directors : </p>
          {movieDetails.directors.map((item) => (
            <li key={item.directorId} style={{fontSize:'12px'}}>
              <Link style={{textDecoration:'none', color:'white'}} to={item.directorUrl}>{item.directorName}</Link>
            </li>
          ))}

          <p> Genres : </p>
          {movieDetails.movieGenres.map((item) => (
            <li key={item.movieGenreId} style={{fontSize:'12px'}}>
              {item.movieGenreName}
            </li>
          ))}


	</div>
           <div style={{paddingLeft:'50px', paddingRight:'100px'}}>
             <h4> Coming Soon... we will be releasing Movie Songs as well. So Stay Tuned. </h4>
	   </div>

	</div> 
        </div>
        ) : (
          <p/>
        )}
    {!isMobile && movieDetails ? (<div style={{display:'flex', textAlign:'left'}}>
	<div style={{display:'block'}}>

	   <div>
	   { 
             (movieDetails != null && movieDetails.movie.movieTrailerCode != null) && (<VideoPlayer title="Movie Trailer" videoId={movieDetails.movie.movieTrailerCode} isMobile={isMobile} />)
	   }
	   </div>

	   <div>
	   { 
             (movieDetails != null && movieDetails.movie.movieCode != null) && (<VideoPlayer title="Movie Video" videoId={movieDetails.movie.movieCode}  isMobile={isMobile}/>)
	   }
	   </div>

           <br/>
           <br/>
           <br/>

           <div style={{paddingLeft:'50px', paddingRight:'100px'}}>
             <h2> Coming Soon... we will be releasing Movie Songs as well. So Stay Tuned. </h2>
	   </div>

	</div>

	<div>
	  <h3> Movie Details </h3>

          <Image
            source={movieDetails.movie.moviePoster}
            alt={movieDetails.movie.movieName}
            style={styles.defaultImage}
          />

	  <p> Movie : {movieDetails.movie.movieName} </p>
	  <p> Movie Release Date : {movieDetails.movie.movieReleaseDate} </p>
	  <p> Language : {movieDetails.movie.movieLanguage} </p>

          <p> Cast : </p>
          {movieDetails.actors.map((item) => (
            <li key={item.actorId} style={{fontSize:'12px'}}>
              <Link style={{textDecoration:'none', color:'white'}} to={item.actorUrl}>{item.actorName}</Link>
            </li>
          ))}

          <p> Directors : </p>
          {movieDetails.directors.map((item) => (
            <li key={item.directorId} style={{fontSize:'12px'}}>
              <Link style={{textDecoration:'none', color:'white'}} to={item.directorUrl}>{item.directorName}</Link>
            </li>
          ))}

          <p> Genres : </p>
          {movieDetails.movieGenres.map((item) => (
            <li key={item.movieGenreId} style={{fontSize:'12px'}}>
              {item.movieGenreName}
            </li>
          ))}


	</div> 
        </div>
        ) : (
          <p>Loading data...</p>
        )}
    </div>
  );
}
const styles = {
  defaultImage: {
    width: '180px',
    height: '260px',
    cursor: 'pointer',
    borderRadius: 8,
    border: '1px solid white',
  },
};

export default MovieDetails;

