//Copyrights dhoomdb.com
//
import React, { useState } from 'react';
//import { View, Text, Image, ScrollView } from 'react';
import { Image } from 'react-native';
import { useNavigate } from 'react-router-dom';

function MovieImageComponent(props) {

  const navigate = useNavigate();

  const [isClicked, setIsClicked] = useState(false);

  // Function to handle the click event
  const handleClick = (movieId) => {
    console.log("Inside handleClick movieId :" + movieId);
    navigate('/moviedetails', { state: { movieId: movieId } });

  };

  return (
    <div style={styles.titleDesc}>
      {/* Add your image tag with the onClick event */}
      <Image
        source={props.data_item.moviePoster}
        alt={props.data_item.movieName}
        style={isClicked ? styles.clickedImage : styles.defaultImage}
        onClick={() => handleClick(props.data_item.movieId)}
      />
      {props.data_item.movieName}
      <br />
      {props.data_item.movieReleaseYear}
    </div>
  );
}

const styles = {
  titleDesc: {
    color: 'white',
    display: 'table-caption',
    fontSize: '12px',
    textAlign: 'center',
  },
  defaultImage: {
    margin: '20px',
    width: '180px',
    height: '260px',
    cursor: 'pointer',
    borderRadius: 8,
    border: '1px solid white',
  },
  clickedImage: {
    width: '150px',
    height: '200px',
    border: '2px solid red',
    cursor: 'pointer',
    borderRadius: 8,
  },
};

export default MovieImageComponent;

