import React from 'react';
import VideoPlayer from './VideoPlayer';

const Songs = (props) => {
  const queryParameters = new URLSearchParams(window.location.search)
  const videoId = queryParameters.get("videoId")

  return (
    <div style={{display:'flex'}}>
	<div>
	<VideoPlayer videoId={videoId} />
	</div>
	<div>
	<p> Well it's the player </p>
	</div>
    </div>
  );
}

export default Songs;

