// ActorPagination.js

import React from 'react';
import './ActorPagination.css'; // Import your custom CSS file for styling
import ActorImageComponent from './ActorImageComponent.js';

function ActorPagination({ selectedActor, actors, onActorChange }) {

  if (selectedActor == null) {
    if (actors != null) {
      if (actors[0] != null) {
        //selectedActor = actors[0].actorName;
        selectedActor = actors[0];
      }
    }
  }

  return (
    <div className="actor-pagination">
      {actors.map((actor) => (
        <ActorImageComponent
          key={actor.actorId}
          data_item={actor} isClicked={actor.actorName === selectedActor.actorName ? true : false} selectedActor={selectedActor} onClick={onActorChange} />
      ))}
    </div>
  );
}

export default ActorPagination;
