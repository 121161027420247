import React, { useEffect, useState } from 'react';
import MovieImageComponent from './MovieImageComponent';
import DropDown from './DropDown';
import YearPagination from './YearPagination';
import ActorPagination from './ActorPagination';
import DecadePagination from './DecadePagination';
import './Movies.css';
import SearchActor from './SearchActor';
import './prevnextstyles.css';
import config from './config';
import ComingSoon from './ComingSoon';

const Movies = (props) => {
  console.log("Movies lang=" + props.lang);
  console.log("Movies category=" + props.category);
  const lang = props.lang;
  const category = props.category;
  const [data, setData] = useState([]);
  const [actorsToShow, setActorsToShow] = useState(null);
  const currentYear = new Date().getFullYear();
//  const currentDecade = "2020s";
  const currentDecade = 2000 + Math.round((currentYear % 100)/10) * 10 + "s"; //ends up being 2020s
  console.log("currentDecade =" + currentDecade);
  const [isConditionMet, setIsConditionMet] = useState(false);

  const itemsPerPage = 10; // Number of movie items to fetch
  const actorsPerPage = 5; // Number of movie items to fetch

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const [actorsCurrentPage, setActorsCurrentPage] = useState(1);
  const [actorsTotalPageCount, setActorsTotalPageCount] = useState(0);

  //var totalPageCount = 0;

  const [loading, setLoading] = useState(true);

  const [selectedYear, setSelectedYear] = useState(currentYear);
  //const [selectedActor, setSelectedActor] = useState(null);
  const [selectedActorObj, setSelectedActorObj] = useState(null);
  //const [selectedActorId, setSelectedActorId] = useState(null);
  const [selectedDecade, setSelectedDecade] = useState(currentDecade);


  const originUrl = window.location.origin;
  console.log("originUrl = " + originUrl);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleActorsPageChange = (newPage) => {
    setActorsCurrentPage(newPage);
    setActorsToShow(null);
    //setSelectedActor(null);
    setSelectedActorObj(null);
  };

  // Reset input state when the active tab changes
  useEffect(() => {
    console.log("Resetting currentPage from :" + currentPage + " to 1");
    setCurrentPage(1); // Reset the input field whenever the tab changes
  }, [category, selectedYear, selectedDecade]); // Dependency on activeTab to trigger reset

  useEffect(() => {
    console.log("useEffect with category :" + category + " called");
    if ( category === "latestMoviesTab" ) {
        console.log("UseEffect latestMoviesTab");
        fetchMovies(0, currentPage, itemsPerPage).then((result) => {
          // The following will concatenate the new data to the previous data
          //setData((prevData) => [...prevData, ...result]);
          console.log("count :" + result.count);
          const pgCnt = Math.ceil(result.count/itemsPerPage);
          setTotalPageCount(pgCnt);
          console.log("currentPage :" + currentPage);
          console.log("totalPageCount :" + pgCnt);
          setData(result.data);
        });
    } else if ( category === "moviesByYearTab" ) {
        console.log("UseEffect moviesByYearTab");
        fetchMovies(selectedYear, currentPage, itemsPerPage).then((result) => {
          console.log("Data retrieved");
          console.log("count :" + result.count);
          const pgCnt = Math.ceil(result.count/itemsPerPage);
          setTotalPageCount(pgCnt);
          console.log("currentPage :" + currentPage);
          console.log("totalPageCount :" + totalPageCount);
          setData(result.data);
          //setData(result);
        });
    }
    else if ( category === "moviesByActorTab" ) {
        console.log("UseEffect moviesByActorTab");
        if (actorsToShow == null) {
            fetchActors(lang, actorsCurrentPage, actorsPerPage).then((result) => {
                console.log("actors count :" + result.count);
                const pgCnt = Math.ceil(result.count/actorsPerPage);
                setActorsTotalPageCount(pgCnt);
                console.log("actorsCurrentPage :" + actorsCurrentPage);
                console.log("actorsTotalPageCount :" + pgCnt);
                setActorsToShow(result.data);
            });
        } else {
            console.log("UseEffect moviesByActorTab fetching movies");
            //if ( selectedActor != null ) {
            if ( selectedActorObj != null ) {
                //fetchMoviesByActor(selectedActorId, currentPage, itemsPerPage).then((result) => {
                fetchMoviesByActor(selectedActorObj.actorId, currentPage, itemsPerPage).then((result) => {
                  console.log("Data retrieved after fetchMoviesByActor");
                  console.log("count :" + result.count);
                  const pgCnt = Math.ceil(result.count/itemsPerPage);
                  setTotalPageCount(pgCnt);
                  console.log("currentPage :" + currentPage);
                  console.log("totalPageCount :" + totalPageCount);
                  setData(result.data);
                  //setData(result);
                });
            }
        }
    }
  }, [category, currentPage, selectedYear, selectedActorObj]);

  const fetchMovies = async (year, m_currentPage, m_itemsPerPage) => {

    console.log('Inside fetchMovies for year :' + year);

    let jsonData = [];

    const url = `${config.apiUrl}/movies?year=${year}&lang=${lang}&page=${m_currentPage}&pageSize=${m_itemsPerPage}`;
    //const url = `http://localhost:3001/movies?year=${year}&lang=${lang}&page=${currentPage}&pageSize=${itemsPerPage}`;
    //const url = `http://192.168.1.168:3001/movies?year=${year}&lang=${lang}`;
    //const url = 'http://localhost:3001/movies' + '?year=' + year;
    //const url = 'http://192.168.1.168:3001/movies';

    console.log("url in fetchMovies: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();

      console.log('Before setSelected Inside fetchMovies for year :' + year);
      //setSelectedYear(year);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  const fetchMoviesByActor = async (actorId, m_currentPage, m_itemsPerPage) => {

    console.log('Inside fetchMoviesByActor for actor :' + actorId);

    let jsonData = [];

    const url = `${config.apiUrl}/movies/actor?actorId=${actorId}&page=${m_currentPage}&pageSize=${m_itemsPerPage}`;
    //const url = `http://localhost:3001/movies/actor?actorId=${actorId}&page=${currentPage}&pageSize=${itemsPerPage}`;

    console.log("url in fetchMoviesByActor: " + url);

    try {
      const response = await fetch(url);
      jsonData = await response.json();

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  // Helper function that returns the first year of the decade when called
  //
  function getCurrentYear(decade) {

    var currentYear = new Date().getFullYear();

    switch (decade) {
      case '2030s':
        break;
      case '2020s':
        currentYear = 2020;
        break;
      case '2010s':
        currentYear = 2010;
        break;
      case '2000s':
        currentYear = 2000;
        break;
      case '1990s':
        currentYear = 1990;
        break;
      case '1980s':
        currentYear = 1980;
        break;
      case '1970s':
        currentYear = 1970;
        break;
      case '1960s':
        currentYear = 1960;
        break;
      case '1950s':
        currentYear = 1950;
        break;
      case '1940s':
        currentYear = 1940;
        break;
      case '1930s':
        currentYear = 1930;
        break;
      default:
        currentYear = new Date().getFullYear();
        break;
    }

    return currentYear;

  }

  function YearPaging({decade, year}) {

    var thisYear = new Date().getFullYear();
    var m_currentYear = getCurrentYear(decade);
    var yearsPerPage = 10; // Number of years to display per page

    if (decade === "2020s") {
      yearsPerPage = thisYear - 2019; // we want everything from 2020 till current year
    }

    if (thisYear === year) year = m_currentYear;

    console.log("Year in YearPaging:" + year);
    console.log("selectedYear in YearPaging:" + selectedYear);

    //setSelectedYear(year);

    console.log("Inside YearPaging currentYear :" + m_currentYear);
    console.log("Inside YearPaging year :" + year);
    console.log("YearPaging decade :" + decade);

    const onYearChange = (year) => {
      console.log('onYearChange clicked year:' + year);
      setSelectedYear(year);
      console.log("onYearChange selectedYear :" + selectedYear);
/*
      fetchData(2000, currentPage, itemsPerPage).then((result) => {
        setData(result.movies); 
      });
*/
    };

    // Calculate the range of years to display based on the selected year
    //const startYear = (Math.ceil(selectedYear / yearsPerPage) - 1) * yearsPerPage + 3;
    const startYear = m_currentYear;
    //const endYear = startYear + yearsPerPage - 1;

    // Generate an array of years for the current page
    const yearsToShow = Array.from({ length: yearsPerPage }, (_, index) => startYear + index);

    console.log("Years to show :" + yearsToShow);
    console.log("Selected Year :" + selectedYear);

    return (
      <div>
      <div style={{paddingLeft:0, display:'flex', flexWrap:'wrap'}}>
        <p>Selected Year: {selectedYear}</p>
      </div>
      <div>
        <YearPagination selectionYear={selectedYear} years={yearsToShow} onYearChange={onYearChange}/>
        {/* Other components or content */}
      </div>
      </div>
    );

  }

  const fetchActors = async (m_lang, m_actorsCurrentPage, m_actorsPerPage) => {

    console.log('Inside fetchActors for lang :' + lang);

    let jsonData = [];

    const url = `${config.apiUrl}/actors?lang=${m_lang}&page=${m_actorsCurrentPage}&pageSize=${m_actorsPerPage}`;
    //const url = `http://localhost:3001/actors?lang=${lang}&page=${currentPage}&pageSize=${itemsPerPage}`;

    console.log("url in fetchActors: " + url);

    try {
      //const response = await promiseOrTimeout;
      const response = await fetch(url);
      console.log('Before fetchActors JsonData:' + response);

      jsonData = await response.json();
      console.log('After fetchActors JsonData:' + response);

      //setData(jsonData);
      //setSelectedActor(year);

      return jsonData;

    } catch (error) {
      jsonData = []; 
      console.error('Error fetching data:', error);
    } finally{
	setLoading(false);
    }
  };

  function DecadePaging() {

    console.log("In Decades:");

    const onDecadeChange = (decade) => {
      console.log('onDecadeChange clicked' + decade);
      //TBD: add fetchData by Actor API
      //fetchData(actor).then((result)
      setSelectedDecade(decade);
      let year = getCurrentYear(decade); // this is the default year when decade is clicked

      console.log("Year in onDecadeChange =" + year);
      setSelectedYear(year);

/*
      fetchData(year, currentPage, itemsPerPage).then((result) => {
        setData(result.movies); 
      });
*/
    };

    // Generate an array of years for the current page
    //const actorsToShow = Array.from("Amitabh");
    //const actorsToShow = fetchActors(lang);
    const decadesToShow = ["2020s", "2010s", "2000s", "1990s", "1980s", "1970s", "1960s","1950s", "1940s", "1930s"];

    console.log("decadesToShow :" + decadesToShow);

    return (
      <div>
      {decadesToShow ? (
	<div>
          <div style={{paddingLeft:0, display:'flex', flexWrap:'wrap'} }>
            <p>Selected Decade: {selectedDecade}</p>
          </div>
          <div style={{display:'flex', overflowX:'scroll', whiteSpace:'nowrap'}}>
            <DecadePagination selectedDecade={selectedDecade} decades={decadesToShow} onDecadeChange={onDecadeChange}/>
            {/* Other components or content */}
          </div> 
          <div>
              <YearPaging decade={selectedDecade} year={selectedYear}/>
          </div>
	    </div>
	    ) : (
            <ComingSoon lang={selectedYear}/>
          )}
      </div>
    );
  }

  function ActorPaging() {

    console.log("lang in ActorPaging:" + lang);

    const onActorChange = (actorObj) => {
      console.log('onActorChange clicked actorName=' + actorObj.actorName + ' actorId=' + actorObj.actorId);
      //TBD: add fetchData by Actor API
      //fetchData(actor).then((result)

      //setSelectedActorId(actorId);
      //setSelectedActor(actorName);

      setSelectedActorObj(actorObj);
    };

    // Generate an array of years for the current page
    //const actorsToShow = Array.from("Amitabh");
    //const actorsToShow = fetchActors(lang);
    //const actorsToShow = ["Amitabh", "Dharmendra", "Rajesh"];

    console.log("actorsToShow :" + actorsToShow);

    //const actorsToShow = helperParseActors(actorsJSONArray);

    if (selectedActorObj == null) {
        if (actorsToShow != null) {
            if (actorsToShow[0] != null) {
              //setSelectedActorId(actorsToShow[0].actorId);
              //setSelectedActor(actorsToShow[0].actorName);
              setSelectedActorObj(actorsToShow[0]);
            }
        }
    }

    return (
      <div>
      {actorsToShow ? (
	<div>
{/*
          <SearchActor/>
*/}
          <div>
          </div>
          <div style={{paddingLeft:20, display:'flex', flexWrap:'wrap'} }>
            <p>Selected Actor: {selectedActorObj ? selectedActorObj.actorName : "Actor Loading"}</p> 
          </div>

          <div style={{display:'flex', overflowX:'scroll', whiteSpace:'nowrap'}}>

              <div className="prev-next-actors-buttons">
                  <button onClick={() => handleActorsPageChange(actorsCurrentPage - 1)} disabled={actorsCurrentPage === 1}>
                     Prev
                  </button>
              </div>

              <ActorPagination selectedActor={selectedActorObj} actors={actorsToShow} onActorChange={onActorChange}/>
                    {/* Other components or content */}

              <div className="prev-next-actors-buttons">
                  <button onClick={() => {handleActorsPageChange(actorsCurrentPage + 1)}} disabled={actorsCurrentPage === actorsTotalPageCount}>
                     Next
                  </button>
              </div>

          </div> 
	</div>
	    ) : (
            <ComingSoon lang={selectedYear}/>
          )}
      </div>
    );
  }

  return (
<div>
    <div>
      {
	((props.category === 'moviesByYearTab') && 
	( 
			<DecadePaging/>
	)) 
        ||
	((props.category === 'moviesByActorTab') && 
	( 
			<ActorPaging/>
	))
        ||
	((props.category === 'moviesByDirectorTab') && 
	( 
			<ComingSoon lang="Movies By Director"/>
	))
        ||
	((props.category === 'moviesByGenreTab') && 
	( 
			<ComingSoon lang="Movies By Genre"/>
	))
      }
      {data && ((props.category === 'latestMoviesTab') | (props.category === 'moviesByYearTab') | (props.category === 'moviesByActorTab')) ? (
	  <div>
            <ul style={{paddingLeft:50, display:'flex', flexWrap:'wrap'}}>
            {data.map((item) => 
	      <li key={item.movieId} style={{listStyle:'none', padding:10}}>
		  <MovieImageComponent data_item={item} />
	      </li>
            )}
            </ul>
       </div>
      ) : 
            ((props.category === 'moviesByYearTab') && <ComingSoon lang={selectedYear}/>)
            ||
            ((props.category === 'moviesByActorTab') && (selectedActorObj != null) && <ComingSoon lang={selectedActorObj.actorName}/>)
            ||
            ((props.category === 'latestMoviesTab') && <ComingSoon lang={lang}/>)
      }
            <div className="prev-next-buttons">
               <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                 Prev
               </button>
               <button onClick={() => {console.log("currentPage=" + currentPage + " totalPageCount =" + totalPageCount); handlePageChange(currentPage + 1)}} disabled={currentPage === totalPageCount}>
                 Next
               </button>
	    </div>
    </div>
</div>
  );
}

export default Movies;

