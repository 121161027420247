import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navigation from './Navigation';
import Home from './Home';
import Movies from './Movies';
import Songs from './Songs';
import Music from './Music';
import Contact from './Contact';
import About from './About';
import Privacy from './Privacy';
import TermsOfService from './TermsOfService';
import VideoPlayer from './VideoPlayer';
import LandingPage from './LandingPage';
import MovieDetails from './MovieDetails';
import './App.css';
import { useMediaQuery } from 'react-responsive';

import Footer from './Footer';

const App = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  var m_paddingLeft = 50;

  if ( isMobile ) {
    m_paddingLeft = 10;
  }

  return (
    <div>
      <div style={{ color: 'white', paddingLeft: m_paddingLeft, paddingRight: 15, paddingTop: 50, fontFamily: 'Copperplate, Papyrus, fantasy', backgroundSize: 'cover', backgroundImage: `url("/images/background.png")` }}>

        <Navigation />

        {/* <Greeting name="Welcome Music Lovers!" /> */}

        <Router>
          <Routes>
            <Route exact path="/" element={<Home name="Home" />} />
            <Route path="/movies" element={<Movies name="Movies" />} />
            <Route path="/songs" element={<Songs name="Songs" />} />
            <Route path="/music" element={<Music name="Music" />} />
            <Route path="/contact" element={<Contact name="Contact" />} />
            <Route path="/about" element={<About name="About Us" />} />
            <Route path="/privacy" element={<Privacy name="Privacy" />} />
            <Route path="/terms" element={<TermsOfService name="Terms Of Service" />} />
            <Route path="/play" element={<VideoPlayer videoId="Xe--hgPX5xw" />} />
            <Route path="/landing" element={<LandingPage name="LandingPage" />} />
            <Route path="/moviedetails" element={<MovieDetails name="MovieDetails" />} />
          </Routes>
        </Router>

        <Footer />

      </div>
    </div>

  );
}

export default App;

