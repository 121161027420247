// TabsPage.js
import React from 'react';
import Tabs from './Tabs';

const TabsPage = (props) => {
  const tabs = [
    { key: 'latestMoviesTab', label: 'Latest Movies', content: <div>Content for Tab 1</div> },
    { key: 'moviesByYearTab', label: 'Movies By Year', content: <div>Content for Tab 2</div> },
    { key: 'moviesByActorTab', label: 'Movies By Actor', content: <div>Content for Tab 3</div> },
    { key: 'moviesByDirectorTab', label: 'Movies By Director', content: <div>Content for Tab 3</div> },
    { key: 'moviesByGenreTab', label: 'Movies By Genre', content: <div>Content for Tab 3</div> },
  ];

  return (
    <div>
      <Tabs lang={props.lang} tabs={tabs} defaultTabKey="latestMoviesTab" />
    </div>
  );
};

export default TabsPage;
