import React from 'react';

const Privacy = (props) => {
  return (
      <center>
      <h1>
<br/>
<br/>
<br/>
        {props.name} | Coming soon...
<br/>
      </h1>
<br/>
<br/>
<br/>
      <p>
      Thank you for checking us out! This website is still in the process of improvement. Keep checking us, we will be updating our website frequently.  </p>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
      </center>

  );
}

export default Privacy;

