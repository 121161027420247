import React from 'react';
import TabsPage from './TabsPage';
import ComingSoon from './ComingSoon';

const LandingPage = () => {
  const params = new URLSearchParams(window.location.search);
  const lang = params.get("lang");
  console.log("Lang =" + lang);
  return (
    ((lang === "Hindi" | lang === "Telugu" | lang === "Tamil") && (
    <div>
        <div>
          <TabsPage lang={lang}/>
        </div>
    </div>)) 
    || 
    <ComingSoon lang={lang}/>
  );
}

export default LandingPage;

