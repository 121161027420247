import React from 'react';
import YouTube from 'react-youtube';

// VideoPlayer.js

const VideoPlayer = (props) => {

  // Replace 'YOUR_YOUTUBE_VIDEO_ID' with the actual ID of the YouTube video you want to embed
  //const videoId = 'YOUR_YOUTUBE_VIDEO_ID';
  //const videoId = '68OWKq-aEOI';

  const isMobile = props.isMobile;

  var m_height = 360;
  var m_width = 640;

  var m_paddingLeft = '100px';

  if ( isMobile && isMobile != undefined  ) {
    m_height = 150;
    m_width = 300;
    m_paddingLeft = '40px';
  }

  console.log("height =" + m_height + " width =" + m_width);

  // Optional: You can specify options for the YouTube player
  const opts = {
    height: m_height,
    width: m_width,
    playerVars: {
      // You can customize the video player's behavior with playerVars
      // For example, to hide related videos, show controls, etc.
      autoplay: 0, // Autoplay the video
    },
  };

  console.log("Inside VideoPlayer");
  console.log("isMobile :" + isMobile);

  console.log("videoId=" + props.videoId);

  return (
    <div style={{paddingLeft:m_paddingLeft, paddingRight:'100px'}}>
      <div>
        <h3> {props.title}</h3>
      </div>
      <div>
        <YouTube videoId={props.videoId} opts={opts} />
      </div>
    </div>
  );
}

export default VideoPlayer;

